<template>
  <div class="body">
    <templateHeader />
    <div>
      <router-view :key="$route.fullPath"></router-view>
    </div>
    <templateFooter />
  </div>
</template>

<script>
import templateHeader from "./templateHeader";
import templateFooter from "./templateFooter";
// import Index from "./index";
export default {
  name: "Home",
  components: {
    templateHeader,
    templateFooter,
    // Index
  },
  data() {
    return {};
  },

  mounted() {
    let _token = JSON.stringify(this.$route.query.taken);
    let _tenantId = JSON.stringify(this.$route.query.domainTid);
    if (_token) {
      window.localStorage.setItem("token", _token);
    }
    if(_tenantId){
      window.localStorage.setItem("domainTid", _tenantId);
    }
  },
};
</script>
<style lang="scss" scoped>
  .body{
    background-color: #f4f4f4;
    color: #5c5c5c;
  }
</style>
