<template>
  <div>
    <div class="logo">
      <div class="logo_top">
        <el-image v-if="getTenantData" class="img" :src="echoImg(getTenantData.indexLogo)"></el-image>
      </div>
      <!--      <div class="loginAdmin" @click="loginAdmin">进入后台</div>-->
      <ul class="userBox" v-if="token">
        <li @click="loginAdmin">进入后台</li>
        <li @click="logouts">退出登录</li>
      </ul>
      <ul class="userBox" v-else>
        <li @click="login(3)">注册</li>
        <li @click="login(1)">登录</li>
      </ul>
      <!--      <ul class="auth-login">-->
      <!--        <li @click="login">注册</li>-->
      <!--        <li @click="login">登录</li>-->
      <!--      </ul>-->
    </div>
    <div class="navbar" :style="'background:' + color">
      <ul class="ul" v-if="navsList">
        <li class="active">
          <span class="text_a" @click="goIndex"> 首页 </span>
        </li>
        <template v-for="(item,index) in navsList" @key="index">
          <li v-if="index<9" v-on:mouseover="changeActive($event, item.witem.length)" v-on:mouseout="removeActive($event, item.witem.length)">
            <span class="text_a" @click="golink(item.navigationName, item.id, item.navigationType)">{{ item.navigationName }}</span>
            <div class="menu">
              <p v-for="(dates, idx) in item.witem" :key="idx">
                <span @click=" golink(dates.navigationName, dates.id, item.navigationType)">{{ dates.navigationName }}</span>
              </p>
            </div>
          </li>
        </template>

      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  getTenant,
  getNavigationTemplate,
  getTenantInfo,
  websiteTemplate,
} from "@/api/template";
import { logout } from "@/api/user";
export default {
  name: "TemplateHeader",
  components: {},
  computed: {
    ...mapState(["color"]),
  },
  data() {
    return {
      activeIndex: 0,
      navsList: [],
      getTenantData: "",
      token: "",
      loginCode: "",
    };
  },
  created() {
    // this.getGetTenantInfo();
    this.getGetTenant();
    this.token = window.localStorage.getItem("token");
  },
  methods: {
    init(tid) {
      let self = this;
      self.navsList = [];
      getNavigationTemplate(tid).then((res) => {
        if (res.code == 200) {
          self.navsList = res.data;
        } else {
          self.$message(res.msg);
        }
      }).catch(() => {});
      websiteTemplate(tid).then((res) => {
        // this.color = res.data.colour;
        this.$store.dispatch("saveColor", res.data.colour);
      });
    },
    getGetTenantInfo() {
      let dname = window.location.host;
      getTenantInfo({ code: dname }).then((res) => {
        if (res.data) {
          window.localStorage.setItem("domainTid", res.data.tenantId);
          this.getGetTenant();
        }
      });
    },
    changeActive(e, length) {
      if (length > 0) {
        e.currentTarget.className = "active";
      }
    },
    removeActive(e, length) {
      if (length > 0) {
        e.currentTarget.className = "";
      }
    },
    goIndex() {
      this.$router.push("/template-first");
    },
    getGetTenant() {
      let that = this
      getTenant().then((res) => {
        if (res.code == 200) {
          this.getTenantData = res.data;
          this.init(res.data.tid);
          window.localStorage.setItem("tenant_id", res.data.tenantId);
          window.localStorage.setItem("loginCode", res.data.loginCode);
          this.loginCode = res.data.loginCode;
          document.title = res.data.loginTitle
          if (res.data.icon) {
            var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = that.echoImg(res.data.icon);
            document.getElementsByTagName('head')[0].appendChild(link);
          }
        } else {
          this.$message(res.msg);
        }
      }).catch(() => {});
    },
    handleSelect() {},
    golink(navigationName, id, type) {
      let data = {
        name: navigationName,
        id: id,
        type: type,
      };
      window.sessionStorage.setItem("navigationValue", JSON.stringify(data));
      // if (this.$route.name == "first-list") {
      //   this.$router.push({
      //     params: { name: navigationName, id: id },
      //     name: "first-transfer",
      //   });
      // } else {
      //   this.$router.push({
      //     params: { name: navigationName, id: id },
      //     name: "first-list",
      //   });
      // }
      if(this.$route.name == 'first-list'){
        this.$router.push({params: {name:navigationName,id:id,type: type},name:'first-transfer'})
      }else {
        this.$router.push({params: {name:navigationName,id:id,type: type},name:'first-list'})
      }
    },
    login(active) {
      if (this.loginCode) {
        window.location.href =
          "http://" + this.loginCode + "/login?redirect=" + active;
      } else {
        window.location.href =
          process.env.VUE_APP_SAAS_URL + "/login?redirect=" + active;
      }
    },
    loginAdmin() {
      if (this.loginCode) {
        window.location.href = "http://" + this.loginCode + "/home";
      } else {
        window.location.href = process.env.VUE_APP_SAAS_URL;
      }
    },
    logouts() {
      let _token = window.localStorage.getItem("token");
      logout(_token).then(() => {
        this.token = "";
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("user");
        this.$store.dispatch("saveUser", []);
        this.$store.dispatch("saveHeadActive", "/index");
        this.$store.dispatch("saveSideActive", "/user");
        // if(this.loginCode){
        //   window.location.href = 'http://' + this.loginCode
        // }else {
        //   window.location.href = process.env.VUE_APP_SAAS_URL
        // }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.logo {
  position: relative;
  .logo_top {
    width: 1000px;
    height: 134px;
    margin: 0 auto;
    .img {
      height: 90px;
      margin-top: 20px;
    }
    .auth-login {
      position: absolute;
      top: 20px;
      right: 10px;
      color: #0000ee;
      cursor: pointer;
      li {
        width: 60px;
        float: left;
        a {
          color: #1989fa;
        }
        :hover {
          background: #1e3c5a;
        }
      }
    }
  }
  .loginAdmin {
    position: absolute;
    right: 20px;
    top: 55px;
    color: #0000ee;
    cursor: pointer;
  }
  .userBox {
    position: absolute;
    right: 10px;
    top: 55px;
    color: #0000ee;
    cursor: pointer;
    li {
      float: left;
      margin: 0 10px;
    }
  }
}
.navbar {
  width: 100%;
  height: 80px;
  background: #1e3c5a;
  //border-bottom: 10px #003366 solid;
  .ul {
    width: 1000px;
    display: flex;
    flex-flow: row wrap;
    justify-content: start;
    margin: 0 auto;
    color: #fff;
    li {
      position: relative;
      height: 80px;
      line-height: 80px;
      flex: 1;
      text-align: center;
      span {
        color: #fff;
        font-size: 16px;
        cursor: pointer;
      }
      .menu {
        //width: 220px;
        width: 100%;
        height: auto;
        position: absolute;
        top: 70px;
        //left: -20px;
        border: 1px #d9d9d9 solid;
        background-color: #fff;
        display: none;
        z-index: 9;
        box-shadow: #ccc 0px 0px 10px;
        display: none;
        overflow: hidden;
        p {
          //text-align: left;
          //padding-left: 20px;
          height: 40px;
          line-height: 40px;
          color: #5c5c5c;
          cursor: pointer;
          text-align: center;
          span {
            //color: #cc3333;
            display: inline-block;
            cursor: pointer;
            color: #5c5c5c;
            width: 100%;
            height: 40px;
            line-height: 40px;
            overflow: hidden;
            text-align: center;
            font-size: 14px;
          }
        }
      }
    }
    .active {
      .menu {
        display: block;
        animation: myMou 1s ease-out;
        animation-fill-mode: forwards;
      }
    }
  }
}
@keyframes myMou {
  from {
    max-height: 0px;
  }
  to {
    max-height: 500px;
  }
}
</style>
