import request from '@/util/request'

// 用户登录
/**
 * @param {
 *    account:账户
 *    password:密码登录（grant_type:"password"）
 *    code:验证码登录（grant_type:"code"）
 *    grant_type:"password" || "code"
 *    tenant_id:number 1
 * }
 */
export function login(data) {
  return request({
    url: '/api/oauth/Login',
    method: 'post',
    data,
    params: {
      client_id: 'admin',
      client_secret: '123456',
      scope: 'all',
      grant_type: 'password'
    }
  })
}

// 获取当前用户信息
export function getInfo() {
  return request({
    url: '/api/oauth/CurrentUser?type=PT',
    method: 'get',
  })
}

// 退出登录
export function logout() {
  return request({
    url: '/api/oauth/Logout',
    method: 'get'
  })
}

// 锁屏解锁登录
export function unlock(data) {
  return request({
    url: '/api/oauth/LockScreen',
    method: 'post',
    data
  })
}

// 用户注册 :{code，account，password}
export function registerUser(data) {
  return request({
    url: '/api/oauth/register',
    method: 'post',
    data
  })
}

// 用户忘记密码 :{code，account，password}
export function forgetPasswordUser(data) {
  return request({
    url: '/api/oauth/forget',
    method: 'post',
    data
  })
}

// 获取验证码 {account} （ 注册：type：null | 验证码登录 type：0 | 忘记密码 type：1）
export function getCode(params) {
  return request({
    url: '/api/oauth/send/code/phone',
    method: 'get',
    params
  })
}

// 获取租户列表
export function getTeanantIdByUsername(params) {
  return request({
    url: '/api/oauth/getTeanantIdByUsername',
    method: 'get',
    params
  })
}


// 获取登陆人信息
export const getUserInfo = (params) => {
    return request({
        url: '/api/system/Permission/Users/userInfo',
        method: 'get',
        params
    })
}

// 修改个人信息
export const updateUser = (data) => {
    return request({
        url: '/api/system/Permission/Users/updateUserId',
        method: 'POST',
        data
    })
}

// 上传头像
export const uploadAvatar = (data) => {
    return request({
        url: '/api/file/Uploader/userAvatar',
        method: 'POST',
        data
    })
}

// 账号与安全 -- 验证手机号更改手机号{code,phone,type}  （ 0验证手机号，1代表修改手机号）
// {phone}/{code}/{type}
export function getCheckUserphone(params,u) {
  let _params = `${u.phone}/${u.code}/${u.type}`;
  return request({
    url: '/api/system/Permission/Users/userphone/'+_params,
    method: 'get',
    params
  })
}
// 账号与安全 -- 修改用户密码{"id": "",	"userPassword": "",	"validatePassword": ""} 
export function ResetPassword(data,id) {
  return request({
    url: `/api/system/Permission/Users/${id}/Actions/ResetPassword`,
    method: 'POST',
    data
  })
}

// 根据租户 id 获取改租户的头部导航信息
export function getTenantMenu(params,tenantId) {
  return request({
    url: `/api/website/home/navigation/tenantId/${tenantId}`,
    method: 'get',
    params
  })
}

//根据租户查询网站信息
export function getTenant(params) {
  return request({
    url: '/api/system/baseLogin/getTenant',
    method: 'get',
    params
  })
}